// Model
import { IEnvironment } from ".";

// Constants
const baseUrl: string = 'https://www.varaosahaku.fi';
export const seoImageUrl: string = `${baseUrl}/assets/media/general/voh.png`;

export const environment: IEnvironment = {
  orgId: 'varaosahaku',
  favicon: '/assets/media/logos/varaosahaku/favicon.ico',
  logo: '/assets/media/logos/varaosahaku/logo.png',
  logoDark: '/assets/media/logos/varaosahaku/logo-dark.png',
  logoSize: '40', // In px
  baseUrl: baseUrl,
  production: true,
  googleMapKey: 'AIzaSyDg4oGdZQ_bxETypyuaoklOSy_XEVgtUDE',
  isSentryLoggingEnable: true,
  apiBaseUrl: 'https://www.varaosahaku.fi',
  checkoutUrl: 'https://checkout.varaosahaku.fi',
  ownStockUrl: 'https://cid-x.varaosahaku.fi',
  sentryDsn: 'https://aaf157941d154f01ae49b68b0f8e04a8@o472375.ingest.sentry.io/4504253011394560',
  wsEndpoint: 'wss://test.bildelsbasen.se/ws/',
  stockNoPrefix: 'V',
  googleAnalytics: {
    isEnabled: true,
    measurementId: 'G-F2QZ79KHBJ',
    language: 'fi',
    currency: 'EUR'
  },
  defaultSort: {
    column: 'part_price_sort_sek',
    direction: 'desc'
  },
  recaptcha: {
    siteKey: '6LciHOkpAAAAACjpS6C3QlwBZcOJUZCTb3KiXZF3',
  },
  zendesk: {
    isZendeskEnabled: false,
    subDomain: 'bildelsbasen',
    scriptUrl: "https://static.zdassets.com/ekr/snippet.js?key=33b7066c-23ac-447b-ba46-0dc880b371b1"
  },
  featureFlags: {
    is_header_enabled: true,
    is_footer_enabled: true
  },
  visitorSettings: {
    defaultLocale: 'fi-fi',
    defaultCurrency: 'EUR',
    allowedLocale: ['fi-fi', 'sv-se', 'nb-no', 'en-se', 'es-es', 'da-dk', 'fr-fr', 'it-it', 'de-de'],
    allowedCurrency: ['EUR', 'SEK', 'NOK', 'USD', 'DKK', 'ISK']
  },
  vehicleTypeConfigFlag: {
    carParts: true,
    truckParts: true,
    mcScooter: true,
    caravan: false,
    snowmobile: true,
    tyresAndRims: true,
    other: true,
  },
  regnrCountries: [
    {
      id: 246,
      code: 'FI',
      country: 'Finland',
      flag: './assets/media/flags/finland.svg'
    },
    {
      id: 752,
      code: 'SE',
      country: 'Sweden',
      flag: './assets/media/flags/sweden.svg'
    },
    {
      id: 578,
      code: 'NO',
      country: 'Norway',
      flag: './assets/media/flags/norway.svg'
    },
    {
      id: 208,
      code: 'DK',
      country: 'Denmark',
      flag: './assets/media/flags/denmark.svg'
    }
  ],
  homeBannerSliders: [
    {
      image: 'assets/media/banners/varaosahaku/banner_voh_cmp.jpg'
    },
    {
      caption: 'Käytetyn, alkuperäisen varaosan hiilijalanjälki on puhdas nolla',
      image: 'assets/media/banners/varaosahaku/banner_voh_4.png'
    },

    {
      caption: 'Käytetyn, alkuperäisen varaosan hiilijalanjälki on puhdas nolla',
      image: 'assets/media/banners/varaosahaku/banner_voh_5.png'
    },
    {
      caption: 'Käytetyn, alkuperäisen varaosan hiilijalanjälki on puhdas nolla',
      image: 'assets/media/banners/varaosahaku/banner_voh_1.jpg'
    },
    {
      caption: 'Nyt VOH-takuu saatavana merkittyihin varaosiin',
      image: 'assets/media/banners/varaosahaku/VOH-takuu_Paakuva_2017.png'
    },
    {
      caption: 'Kierrätys ja uusiokäyttö on tulevaisuutta!',
      image: 'assets/media/banners/varaosahaku/banner_voh_2.jpg'
    },
    {
      caption: 'Kierrätys ja uusiokäyttö on tulevaisuutta!',
      image: 'assets/media/banners/varaosahaku/banner_voh_3.jpg'
    },
    {
      caption: 'Autopurkamot - hyvä kumppani kiertotaloudessa',
      image: 'assets/media/banners/varaosahaku/banner_voh_5.png'
    }
  ],
  seo: {
    'en-se': {
      title: 'Varaosahaku.fi is Finlands largest quality-classified used car parts website.',
      description: 'you can search and order spare parts from the spare parts file maintained by the countrys leading car dealerships and used spare parts sales stores - easily and quickly.',
    },
    'fi-fi': {
      title: 'Varaosahaku.fi on Suomen suurin laatuluokiteltu käytettyjen autonosien verkkosivusto.',
      description: 'voit hakea ja tilata varaosia varaosatiedostosta, jota ylläpitävät maan johtavat autokaupat ja käytettyjen varaosien myyntiliikkeet - helposti ja nopeasti.',
    },
    fallback: 'en-se',
    tags: [
      { property: 'og:site_name', content: 'Varaosahaku' },
      { property: 'og:type', content: 'website' },
      { property: 'og:image', content: seoImageUrl },
      { name: 'author', content: 'Varaosahaku.fi' },
      { name: 'description', property: 'og:description', content: '' },
      { name: 'robots', content: 'follow,index,notranslate' }
    ]
  }
};